import React from 'react'

import styles from './sujs-footer.module.css'

const amazonUrl = 'https://amzn.to/30EemzB';
const sujsUrl = 'https://books.circlearound.co.jp/step-up-javascript';

function SujsOwner() {
  return (
    <div className={styles.description}>
      このコンテンツは弊社で執筆した書籍「
      <a href={sujsUrl} target="_blank" rel="noreferrer">ステップアップJavaScript</a>
      」の補足情報としても活用されています。<br />
      <ul>
        <li>非同期処理（async/await、Promise）</li>
        <li>AJAX（Fetch API）</li>
        <li>this</li>
        <li>プリミティブ型/オブジェクト型と参照</li>
      </ul>

      などの「よくある躓きポイント」を中心に解説した、初級から中級へステップアップするための指南書です。
      <div className={styles.message}>
        既にお買い上げの方: よかったら本書の<a href={amazonUrl} target="_blank" rel="noreferrer">Amazonのレビュー</a>を書いていただけるととても嬉しいです。
      </div>
    </div>
  )
}

function SujsTarget() {
  return (
    <div className={styles.description}>
      本Webサイトは弊社で執筆した書籍「
      <a href={sujsUrl} target="_blank" rel="noreferrer">ステップアップJavaScript</a>
      」の補足情報としても活用されています。<br />
      <ul>
        <li>非同期処理（async/await、Promise）</li>
        <li>AJAX（Fetch API）</li>
        <li>this</li>
        <li>プリミティブ型/オブジェクト型と参照</li>
      </ul>

      などの「よくある躓きポイント」を中心に解説した、初級から中級へステップアップするための指南書です。
      <div className={styles.message}>
        既に<a href={amazonUrl} target="_blank" rel="noreferrer">Amazon</a>でお買い上げいただけます。参考にしていただければ幸いです。
      </div>
    </div>
  )
}

export default ({ post }) => {
  const sujsSlug = 'step-up-javascript-supplementary-content'
  const hasSujsTag = post.tags?.find(tag => tag.slug === sujsSlug)
  const hasJsTag = post.tags?.find(tag => tag.slug === 'javascript')

  if(!hasSujsTag && !hasJsTag) { return <></>; }

  return (
    <div className={styles.wrapper}>
      <figure className={styles.figure}>
        <a href={sujsUrl} target="_blank" rel="noreferrer">
          <img src="/images/step-up-javascript-cover.png" alt="ステップアップJavaScriptカバー"></img>
        </a>
      </figure>
      { hasSujsTag ? <SujsOwner/> : <SujsTarget/> }
    </div>
  )
}
