import React from "react"
import './article-theme.css'
import moment from 'moment'
import TagList from './tag-list'
import ShareButton from './share-button'
import get from 'lodash/get'

function Youtube(props) {
  return (
    <iframe className='youtube' title="youtube" width="560" height="315" src={props.src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  )
}

function Article({ post }) {
  const host = process.env.HOST || 'techlib.circlearound.co.jp'
  const protcol = process.env.PROTCOL || 'https'
  const postUrl = `${protcol}://${host}/entries/${post.slug}`
  const description = get(post, 'description.description')

  return (
    <article className="article">
      <TagList tags={post.tags} />
      <h1>{post.title}</h1>
      <ShareButton title={`${post.title} - Tech lib`} url={postUrl}/>
      <p className='publishDate'>
        {moment(post.publishDate).local().format('YYYY/MM/DD HH:mm')}
      </p>
      <p>{description}</p>

      <div className='youtubeWrapper'>
        { (post.youtubeUrl && !Array.isArray(post.youtubeUrl) && post.youtubeUrl !== '')? <Youtube key={post.youtubeUrl} src={post.youtubeUrl}></Youtube> : '' }
        { (post.youtubeUrl && Array.isArray(post.youtubeUrl))? post.youtubeUrl.map((url)=>{ return <Youtube key={url} src={url}></Youtube> }) : '' }
      </div>

      <div
          dangerouslySetInnerHTML={{
          __html: post.body.childMarkdownRemark.html,
          }}
      />
    </article>
  )
}

export default Article