import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import Image from './image'
import styles from './article-card.module.css'

export default ({ article }) => (
  <div className={styles.cardWrap}>
    <Link to={`/entries/${article.slug}`} className={styles.cardThumbnail}>
      <Image imageName={`${article.slug}.png`} alt={`${article.title}`} />
    </Link>
    <div className={styles.cardDesc}>
      <h3 className={styles.cardTitle}>
        <Link to={`/entries/${article.slug}`}>
          {article.title}
        </Link>
      </h3>
      <small>{moment(article.publishDate).local().format('YYYY/MM/DD HH:mm')}</small>
    </div>
  </div>
)
