import React from 'react'
import ArticleCard from '../components/article-card'

import styles from './suggest-article.module.css'

export default ({ posts }) => (
  <div>
    <h2 className={styles.headLine}>関連記事</h2>
    <div className={styles.suggestArticlesWrap}>
      <ul className={styles.suggestArticles}>
        {posts.map(node => {
          return (
            <li key={node.slug} className={styles.suggestArticleWrap}>
              <ArticleCard article={node} />
            </li>
          )
        })}
      </ul>
    </div>
  </div>
)
