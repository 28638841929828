import React from "react"
import { Helmet } from "react-helmet"

function PostMeta({ post, ogpImgPath, description, site}) {
  const metaDescription = description || site.siteMetadata.description
  const metaTitle = `${post.title || site.siteMetadata.title} - わかりやすい動画解説 | Tech lib`
  const host = process.env.HOST || site.siteMetadata.host
  const protcol = process.env.PROTCOL || 'https'
  const ogpImgUrl = `${protcol}://${host}${ogpImgPath}`

  return (
    <Helmet
      htmlAttributes={{
        lang: 'ja',
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogpImgUrl,
        },
        {
          property: `og:type`,
          content: `article`, // @see https://ogp.me/#types
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

export default PostMeta