import React from 'react'
import { Helmet } from "react-helmet"
 
export default ({videos}) => {
  let videoObject = {}
  if (videos.length > 1) {
    const videoList = videos.map((video, index)=>{
      return {
        "@type": "VideoObject",
        "position": index+1,
        "url": `https://www.youtube.com/watch?v=${video.videoId}&feature=youtu.be`,
        "name": video.name,
        "description": video.description,
        "embedUrl": `https://www.youtube.com/embed/${video.videoId}`,
        "contentURL": `https://www.youtube.com/watch?v=${video.videoId}&feature=youtu.be`,
        "thumbnailUrl": video.thumbnailUrl,
        "uploadDate": video.uploadDate
      }
    })
    Object.assign(videoObject, {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "itemListElement": videoList
    })
  } else if(videos.length > 0) {
    let video = videos[0]
    Object.assign(videoObject, {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "contentURL": `https://www.youtube.com/watch?v=${video.videoId}&feature=youtu.be`,
      "name": video.name,
      "description": video.description,
      "embedUrl": `https://www.youtube.com/embed/${video.videoId}`,
      "thumbnailUrl": video.thumbnailUrl,
      "uploadDate": video.uploadDate
    })
  }

  return (
    <Helmet>
      <script type="application/ld+json">{`${JSON.stringify(videoObject)}`}</script>
    </Helmet> 
  )
}
