import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import PostMeta from '../components/meta-per-post'
import Article from '../components/article'
import SuggestArticles from '../components/suggest-articles'
import VideoObject from '../components/video-object'
import Image from '../components/image'
import Breadcrumb from '../components/breadcrumb'
import styles from './blog-post.module.css'
import SujsFooter from '../components/sujs-footer'

export default ({data, pageContext, location}) => {
  const post = data.contentfulBlogPost
  const author = post.author
  const authorImg = get(author, 'image.file.url')
  const site = data.site
  const siteDescription = get(post, 'description.description')
  const imagePath = `/images/${post.slug}.png`
  const suggestPosts = post.blogPosts || []
  const videos = pageContext.videos || {}
  const breadcrumbList = [{str: 'トップ', path: '/'}, {str: 'コンテンツ一覧', path: '/entries'}, {str: post.title, path: `/entries/${post.slug}`}]

  return (
    <Layout location={location}>
      <VideoObject videos={videos} />
      <PostMeta 
        description={siteDescription}
        post={post}
        ogpImgPath={imagePath}
        site={site}
      />
      <div style={{ background: '#fff' }}>
        <div>
          <Image imageName={`${post.slug}.png`} alt={`メインビジュアル`} />
        </div>
        <div className="wrapper">
          <Breadcrumb breadcrumbList={breadcrumbList}/>
          <Article post={post} />
          {
            suggestPosts.length > 0 && (<SuggestArticles posts={suggestPosts} />)
          }
          <SujsFooter post={post}/>
          { author ?
            <div className={styles.authorWrap}>
              <div className={styles.thumb} style={{backgroundImage: `url(${authorImg})`}} />
              <div className={styles.profile}>
                <p className={styles.label}>この記事を書いた人</p>
                <p className={styles.name}><a className={styles.nameLink} href={`/authors/${author.nickName}`}>{author.name}</a></p>
                <p className={styles.job}>{author.job}</p>
              </div>
            </div> : <div />
          }
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        tagline
        host
        author
        description
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      publishDate
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      description {
        description
      }
      tags {
        name
        slug
      }
      author {
        name
        job
        nickName
        image {
          file {
            url
          }
        }
      }
      blogPosts {
        title
        slug
        publishDate
        heroImage {
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid
          }
        }
      }
      youtubeUrl
    }
  }
`
